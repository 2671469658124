(function($){
	var defaults = {
		slideTime: 5000,
		slideSelector: '.slide',
		autoSlide: true,
		autoSlideDirection: 'right',
		animateSelector: '.animate',
		appendFirst: false,
		autoSlideCallback: function () {}
	};
	var settings = {}
	var sliding = false;


	var methods = {
		init : function(options) {
			this.addClass('scslider');
			//this.find('.slide .scs-img>img').hide();
			return this.each(function(){
				var self = $(this);
				settings = $.extend({}, defaults, options);
				var inner =  self.find('.scs-inner');

				if(!settings.appendFirst) {
					var slides = inner.find('.slide').slice(0, -1);
				}else{
					var slides = inner.find('.slide');
					inner.append(slides.eq(0).clone(true, true).removeClass('active'));
				}

				self.data('slides',slides);
				self.data('currentSlide', self.find('.slide.active'));
				inner.width(((slides.length+1)*100)+'%');
				self.find(".scs-indicators").children().eq(self.data('currentSlide').index()).addClass('active');
				self.find(".scs-indicators").children().click(function(event) {
					event.preventDefault();
					methods.goToSlide.apply(self,[$(this).data('slide-to')]);
				});
				self.find('.right.scs-control').on("click", function( event ) {
					event.preventDefault();
					if(!sliding)
						methods.nextSlide.apply(self);
				});
				self.find('.left.scs-control').on("click", function( event ) {
					event.preventDefault();
					if(!sliding)
						methods.prevSlide.apply(self);
				});
				inner.find('.slide').on('transitionend webkitTransitionEnd oTransitionEnd',function(event){
					event.stopPropagation()
				})
				methods.refreshIMG.apply(self);
				$(window).on('resize load',function(event) {
					methods.refreshIMG.apply(self);
				});
				
				var time = new Timer(function(){
					if(settings.autoSlideDirection == 'right')
						methods.nextSlide.apply(self);
					else
						methods.prevSlide.apply(self);

					settings.autoSlideCallback.apply(self);
				},settings.slideTime);
				if(!settings.autoSlide)
					time.stop();

				self.data('timer',time);
				if(typeof $.fn.swipe !== 'undefined'){
					self.swipe( {
						swipeRight: function() {
							methods.prevSlide.apply(self);
						},
						swipeLeft: function() {
							methods.nextSlide.apply(self);
						}
					});
				}else{
					console.log("SCSlider: Swipe not found")
				}

				$(this).addClass("scs-inited");
			});        
		},
		refreshIMG: function(){
			var self = $(this);
			/*self.find('.slide').each(function(index, el) {
				var newImg = new Image();
				var slide = $(this)
				var img = slide.find('.scs-img>img');
				
				newImg.onload = function(){
					var newH = slide.height();
					var newW = newImg.width/newImg.height * newH;
					if(newW < slide.width()){
						newW = slide.width();
						newH = newImg.height/newImg.width * newW;
					}

					var animate_els = self.data('currentSlide').find();
					animate_els.each(function(index, el) {
						$(this).addClass('animated').addClass($(this).data('animation'));
					});

					img.width(newW);
					img.height(newH);
					img.show();
				};
				newImg.src = img.prop('src'); 
			});*/
		},
		nextSlide: function(){
			var self = $(this);
			var nextSlide = self.data('currentSlide').next('.slide');

			var slides = self.data('slides');

			var animate_els
			if(nextSlide.is(':last-child'))
				animate_els = slides.eq(0).find(settings.animateSelector);
			else
				animate_els = nextSlide.find(settings.animateSelector);

			animate_els.each(function(index, el) {
				$(this).removeClass('animated').removeClass($(this).data('animation'));
			});

			var indicators = self.find(".scs-indicators").children();
			var inner = self.find('.scs-inner');

			if(inner.hasClass('sliding'))
			    return false;

			self.off('transitionend webkitTransitionEnd oTransitionEnd');
			self.data('timer').stop();

			inner.removeClass('notransition');
			inner.addClass('sliding')
			indicators.removeClass('active');
			inner.css('left',-(nextSlide.index()*100)+'%')
			inner.one('transitionend webkitTransitionEnd oTransitionEnd',function(){
				if(settings.autoSlide)
					self.data('timer').reset();
				self.data('currentSlide').removeClass('active').find(settings.animateSelector).removeClass('animated');
				if(nextSlide.is(':last-child')){
					inner.addClass('notransition');
					inner.css('left','0%');
					self.data('currentSlide',slides.eq(0));
				}else{
					self.data('currentSlide',nextSlide);
				}
				indicators.eq(self.data('currentSlide').index()).addClass('active');
				self.data('currentSlide').addClass('active');

				var animate_els = self.data('currentSlide').find(settings.animateSelector);
				animate_els.each(function(index, el) {
					$(this).addClass('animated').addClass($(this).data('animation'));
				});

				inner.removeClass('sliding')
			});
		},
		prevSlide: function(){
			var self = $(this);

			var indicators = self.find(".scs-indicators").children();
			var inner = self.find('.scs-inner');
			var slides = self.data('slides');
			var nextSlide;

			if(inner.hasClass('sliding'))
				return false;

			self.off('transitionend webkitTransitionEnd oTransitionEnd');
			self.data('timer').stop();

			if(self.data('currentSlide').is(':first-child')){
				inner.addClass('notransition');
				inner.css('left',-((slides.length)*100)+'%');
				inner[0].offsetHeight
				nextSlide = slides.last();
			}else nextSlide = self.data('currentSlide').prev();

			var animate_els = nextSlide.find(settings.animateSelector);
			animate_els.each(function(index, el) {
				$(this).removeClass('animated').removeClass($(this).data('animation'));
			});
			
			inner.addClass('sliding')

			inner.removeClass('notransition');
			inner.css('left',-(nextSlide.index()*100)+'%');

			inner.one('transitionend webkitTransitionEnd oTransitionEnd',function(){
				self.data('currentSlide',nextSlide);

				if(settings.autoSlide)
					self.data('timer').reset();
				self.data('currentSlide').addClass('active');
				indicators.removeClass('active');
				indicators.eq(self.data('currentSlide').index()).addClass('active');
				inner.addClass('notransition');
				inner.removeClass('sliding');

				var animate_els = self.data('currentSlide').find(settings.animateSelector);
				animate_els.each(function(index, el) {
					$(this).addClass('animated').addClass($(this).data('animation'));
				});
			});
		},
		goToSlide: function(slide){
			var self = $(this);
			var inner = self.find('.scs-inner');

			if(inner.hasClass('sliding'))
				return false;

			var slides = inner.find('.slide');
			var nextSlide = slides.eq(slide);

			var animate_els = nextSlide.find(settings.animateSelector);
			animate_els.each(function(index, el) {
				$(this).removeClass('animated').removeClass($(this).data('animation'));
			});

			var current = self.data('currentSlide').index();
			var indicators = self.find(".scs-indicators").children();
			self.off('transitionend webkitTransitionEnd oTransitionEnd');
			self.data('timer').stop();
			inner.css('transition-duration',(Math.abs(current-slide)*0.6)+'s');
			inner.removeClass('notransition');
			inner.css('left',-(slide*100)+'%');
			self.data('currentSlide').removeClass('active');
			if(nextSlide.is(':last-child')){
				inner.one('transitionend webkitTransitionEnd oTransitionEnd',function(){
					inner.addClass('notransition');
					inner.css('left',0);
				})
				self.data('currentSlide',slides.eq(0));
			}else    self.data('currentSlide',slides.eq(slide));
			self.data('currentSlide').addClass('active');
			indicators.removeClass('active');
			inner.one('transitionend webkitTransitionEnd oTransitionEnd',function(){
				if(settings.autoSlide)
					self.data('timer').reset();

				var animate_els = self.data('currentSlide').find(settings.animateSelector);
				animate_els.each(function(index, el) {
					$(this).addClass('animated').addClass($(this).data('animation'));
				});

				indicators.eq(self.data('currentSlide').index()).addClass('active');
				inner.css('transition-duration','');
			});
		},
		pause(){
			var self = $(this);
			self.data('timer').stop();
		},
		setOnAutoSlide(callback){
			settings.autoSlideCallback = callback;
		}
	};
   $.fn.scslider = function(methodOrOptions) {
		if ( methods[methodOrOptions] ) {
			return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
		} else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
			// Default to "init"
			return methods.init.apply(this,arguments);
		} else {
			$.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.scsliderx' );
		}    
	};
	function Timer(fn, t) {
		var timerObj = setInterval(fn, t);

		this.stop = function() {
			if (timerObj) {
				clearInterval(timerObj);
				timerObj = null;
			}
			return this;
		}

		// start timer using current settings (if it's not already running)
		this.start = function() {
			if (!timerObj) {
				this.stop();
				timerObj = setInterval(fn, t);
			}
			return this;
		}

		// start with new interval, stop current interval
		this.reset = function(newT) {
			t = typeof newT !== 'undefined' ? newT : t;
			return this.stop().start();
		}
	}
})(jQuery);
import 'bootstrap';

import '../../common-assets/js/bootstrap-select.min.js';
import '../../common-assets/js/jquery.placeholder.min.js';
import '../../common-assets/js/fontawesome-all.min.js';
import '../../common-assets/src/logger.js';
import '../../common-assets/src/unserializer.js';
import '../../common-assets/src/stylecre.js';

import '../../assets/js/bootstrap-4-hover-navbar.js';
import '../../assets/js/jquery.cover.js';
import '../../assets/js/jquery.scparallaxbg.js';
import '../../assets/js/jquery.scgallery.js';
import '../../assets/js/jquery.scslider.js';
import '../../assets/js/jquery.datetextentry.js';
import '../../assets/src/vue-config';
import '../../assets/js/script.js';

import '../../common-assets/css/bootstrap-select.min.css';

import '../../assets/css/jquery.cover.css';
import '../../assets/css/animate.css';
import '../../assets/css/jquery.scparallaxbg.css';
import '../../assets/css/jquery.scgallery.css';
import '../../assets/css/jquery.scslider.css';
import '../../assets/css/jquery.datetextentry.css';
import '../../assets/scss/main.scss';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    directives: [
      {
        name: "lazy",
        rawName: "v-lazy",
        value: _vm.lazyImg,
        expression: "lazyImg"
      }
    ],
    attrs: { "data-srcset": _vm.c_srcset }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "main-container",
      staticClass: "sc-image-parallax",
      class: { bg: _vm.backDrop },
      style: { height: _vm.height + "px" }
    },
    [
      _c(
        "div",
        {
          ref: "image-container",
          staticClass: "sc-image-parallax-image",
          class: { "is-fixed": _vm.fixed, "is-parallax": _vm.parallax },
          style: {
            transform: "translateY(" + _vm.yTranslation + "px)",
            height: _vm.img.height
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.content
        ? _c(
            "div",
            {
              ref: "content-container",
              staticClass: "sc-image-parallax-content",
              class: { auto: _vm.height }
            },
            [_vm._t("content")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
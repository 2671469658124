<template>
    <div class="v-scs-slide" :class="{active: active, next: isNext}">
        <a :href="href" :title="title" class="v-scs-img">
            <slot name="img">
                <lazy-img
                    class="cover h-100 w-100"
                    :title="title"
                    :alt="title"
                    :src="img"
                    :root="root"
                ></lazy-img>
            </slot>
        </a>
        <div class="v-scs-caption-inner" v-if="$slots.default">
            <div class="v-scs-caption">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import LazyImg from '../LazyImg';

    export default {
        name: "SCSlide",
        components: {
            LazyImg
        },
        props: {
            title: {
                default: "",
                type: String
            },
            href: {
                default: "",
                type: String,
            },
            root: {
                default: '/',
                type: String
            },
            img: {
                default: null,
                type: String
            },
            active: {
                default: false,
                type: String
            },
            isNext: {
                default: false,
                type: String
            },
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .v-scs-slide {
        width: 100%;
        height: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        position: relative;
        text-align: center;
        overflow: hidden;
        position: relative;

        &:not(.active){
            display: none;
        }

        &.active,
        &.next {
            -webkit-transition: transform 0.5s;
            -o-transition: transform 0.5s;
            transition: transform 0.5s;
        }

        .v-scs-img {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            left: 0px;
            margin: auto;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        & > * {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-user-drag: none;
            user-drag: none;
            -webkit-touch-callout: none;
        }
    }
</style>
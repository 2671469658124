var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe",
          value: _vm.swipeHandler,
          expression: "swipeHandler",
          arg: "swipe"
        }
      ],
      staticClass: "v-scslider",
      attrs: { id: _vm.name, "data-ride": "carousel" }
    },
    [
      _c(
        "ol",
        { staticClass: "v-scs-indicators" },
        _vm._l(_vm.slides.length, function(index) {
          return _c(
            "li",
            { class: { active: index - 1 == _vm.currentIndex } },
            [
              _c("span", {
                staticClass: "before",
                style: {
                  width: index - 1 == _vm.currentIndex ? _vm.timerPRC + "%" : 0
                }
              })
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "v-scs-inner",
          class: {
            sliding: _vm.sliding,
            right: _vm.currentDirection == "right",
            left: _vm.currentDirection == "left"
          },
          attrs: { role: "listbox" }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "left v-scs-control",
          attrs: { href: "#" + _vm.name, role: "button", "data-slide": "prev" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.prevSlide($event)
            }
          }
        },
        [
          _vm._t("prev-control", [
            _c("i", {
              staticClass: "far fa-chevron-left",
              attrs: { "aria-hidden": "true" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.prevLabel))
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "right v-scs-control",
          attrs: { href: "#" + _vm.name, role: "button", "data-slide": "next" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.nextSlide($event)
            }
          }
        },
        [
          _vm._t("next-control", [
            _c("i", {
              staticClass: "far fa-chevron-right",
              attrs: { "aria-hidden": "true" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.nextLabel))
            ])
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
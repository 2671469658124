
/*!
 * Bootstrap 4 multi dropdown navbar ( https://bootstrapthemes.co/demo/resource/bootstrap-4-multi-dropdown-navbar/ )
 * Copyright 2017.
 * Licensed under the GPL license
 */

function show_submenu( obj ) {
    var nav = obj.closest('.navbar');
    if (
            ( nav.hasClass('navbar-expand-xl') && $( window ).width() > 1200 ) ||
            ( nav.hasClass('navbar-expand-lg') && $( window ).width() > 992 ) ||
            ( nav.hasClass('navbar-expand-md') && $( window ).width() > 768 ) ||
            ( nav.hasClass('navbar-expand-sm') && $( window ).width() > 576 )
        ) {
        obj.stop( true, true ).delay( 250 ).slideDown( function() {
            obj.parent( ".dropdown" ).addClass( 'show' )
        } );
    }
}

function hide_submenu( obj ) {
    var nav = obj.closest('.navbar');
    if (
            ( nav.hasClass('navbar-expand-xl') && $( window ).width() > 1200 ) ||
            ( nav.hasClass('navbar-expand-lg') && $( window ).width() > 992 ) ||
            ( nav.hasClass('navbar-expand-md') && $( window ).width() > 768 ) ||
            ( nav.hasClass('navbar-expand-sm') && $( window ).width() > 576 )
        ){
        obj.stop( true, true ).delay( 100 ).slideUp( function() {
            obj.parent( ".dropdown" ).removeClass( 'show' )
        } );
    }
}

$( document ).ready( function () {
    $( '.nav-link.dropdown-toggle a,.dropdown-menu .dropdown-toggle a' ).on( 'click', function ( e ) {
        window.location.href = $(this).attr('href');
        e.stopPropagation();
    });
    $( '.dropdown-menu .dropdown-toggle' ).on( 'click', function ( e ) {
        var $el = $( this );
        var $parent = $( this ).offsetParent( ".dropdown-menu" );
        if ( !$( this ).next().hasClass( 'show' ) ) {
            $( this ).parents( '.dropdown-menu' ).first().find( '.show' ).removeClass( "show" );
        }
        var $subMenu = $( this ).next( ".dropdown-menu" );
        $subMenu.toggleClass( 'show' );
        
        $( this ).parent( "li" ).toggleClass( 'show' );

        $( this ).parents( 'li.nav-item.dropdown.show' ).on( 'hidden.bs.dropdown', function ( e ) {
            $( '.dropdown-menu .show' ).removeClass( "show" );
        } );
        
        //  if ( !$parent.parent().hasClass( 'navbar-nav' ) ) {
        //     $el.next().css( { "top": $el[0].offsetTop, "left": $parent.outerWidth() - 4 } );
        // }

        return false;
    } );

    $( '.navbar .nav-item.dropdown, .navbar .dropdown-item.dropright' ).hover( function() {
        show_submenu( $( this ).children('.dropdown-menu') );
    }, function() {
        hide_submenu( $( this ).children('.dropdown-menu') );
    } );

    $( '.navbar .nav-item.dropdown .dropdown-menu, .navbar .dropdown-item.dropright .dropdown-menu' ).hover( function() {
        show_submenu( $( this ) );
    }, function() {
        hide_submenu( $( this ) );
    } );
} );
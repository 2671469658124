const iziToast = require('iziToast');
require('iziToast/dist/css/iziToast.css');

module.exports = class Logger{
	static addError(item,isettings){
		isettings = isettings || {};
		var settings = this.createSettings(item,isettings);
		iziToast.error(settings);
	}
	static addSuccess(item,isettings){
		isettings = isettings || {};
		var settings = this.createSettings(item,isettings);
		iziToast.success(settings);
	}
	static addLog(item,isettings){
		isettings = isettings || {};
		var settings = this.createSettings(item,isettings);	
		iziToast.info(settings);
	}
	static displayLogger(logs,isettings){
		var self = this;
		isettings = isettings || {};
		$.each(logs.successes, function(index,item){
			var settings = self.createSettings(item,isettings);				
			iziToast.success(settings);
		});
		$.each(logs.errors, function(index,item){
			var settings = self.createSettings(item,isettings);				
			iziToast.error(settings);
		});
		$.each(logs.logs, function(index,item){
			var settings = self.createSettings(item,isettings);				
			iziToast.info(settings);
		});
		$.each(logs.debugs, function(index,item){
			console[item.type](item.message);
		});
	}

	static createSettings(item,isettings){
		isettings = isettings || {};
		var defaults = {
			transitionIn: 'bounceInLeft',
			transitionOut: 'fadeOutRight',
			maxWidth: '500px',
			position: 'topRight'
		};

		var settings = $.extend({},defaults,isettings);
		
		if(item.message)
			settings.message = item.message;
		else
			settings.message = item;
		
		if(item.title)
			settings.title = item.title;

		return settings;
		
	}
}
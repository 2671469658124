<template>
    <img
        v-lazy="lazyImg"
        :data-srcset="c_srcset"
    />
</template>

<script>
    import VueLazyload from 'vue-lazyload';

    Vue.use(VueLazyload);

    export default {
        name: "LazyImg",
        props: {
            cachePath: {
                default: 'cache/',
                type: String
            },
            root: {
                default: '/',
                type: String
            },
            src: {
                required: true,
                type: String
            },
            srcset: {
                default: true,
                type: Boolean
            },
            srcsetSize: {
                default: {
                    '100w': 'small',
                    '300w': 'medium',
                    '800w': 'big'
                },
                type: Object
            },
            customSrcset: {
                default: null,
                type: Object

            },
            srcSize: {
                default: null,
                type: String
            },
            originalSize: {
                default: '2000w',
                type: String
            },
            loadingSize: {
                default: 'placeholder',
                type: String
            },
            loadingPlaceholder: {
              default: null,
              type: String
            },
            error: {
                default: "assets/img/placeholder-img.png",
                type: String
            }
        },
        computed: {
            c_cachePath() {
                return this.root + this.cachePath;
            },
            c_src(){
                let is_abs = this.src.startsWith('https://') || this.src.startsWith('http://') ||  this.src.startsWith('//');
                return (!is_abs ? (this.srcSize ? this.c_cachePath + this.srcSize + '/' : this.root) : '') + this.src;
            },
            c_srcset() {
                if(!this.srcset) return '';
                if(this.customSrcset) return this.customSrcset;

                var srcset = [];
                for (const prop in this.srcsetSize) {
                    srcset.push(this.c_cachePath + this.srcsetSize[prop] + '/' + this.src + ' ' + prop);
                }
                srcset.push(this.root + this.src + ' ' + this.originalSize);

                return srcset.concat(', ');
            },
            c_error(){
                return this.root + this.error;
            },
            c_loading(){
                return this.loadingPlaceholder || (this.c_cachePath + this.loadingSize+'/' + this.src);
            },
            lazyImg(){
                return{
                    src: this.c_src,
                    error: this.c_error,
                    loading: this.c_loading
                }
            }
        }
    }
</script>

<style scoped>

</style>
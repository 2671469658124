<template>
	<div
		class="sc-image-parallax"
		:class="{ bg: backDrop }"
		:style="{ height: height + 'px' }"
		ref="main-container"
	>
		<div
			class="sc-image-parallax-image"
			:class="{ 'is-fixed': fixed, 'is-parallax': parallax }"
			:style="{
				transform: 'translateY(' + yTranslation + 'px)',
				height: img.height
			}"
			ref="image-container"
		>
			<slot></slot>
		</div>
		<div
			class="sc-image-parallax-content"
			ref="content-container"
			:class="{ auto: height }"
			v-if="$slots.content"
		>
			<slot name="content"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'image-parallax',
	props: {
		height: {
			type: Number,
			default: null
		},
		delta: {
			type: Number,
			default: 0.1
		},
		parallax: {
			default: true,
			type: Boolean
		},
		fixed: {
			default: false,
			type: Boolean
		},
		backDrop: {
			type: Boolean,
			default: false
		},
		direction: {
			type: String,
			default: 'up'
		}
	},
	data() {
		return {
			yTranslation: 0,
			desviacion: 0,
			offset: null,
			img: {
				width: 'auto',
				height: 'auto'
			},
			el: null
		};
	},
	computed: {
		directionValue() {
			return this.direction === 'down' ? +1 : -1;
		}
	},
	methods: {
		animateElement() {
			var st = document.documentElement.scrollTop;
			var vp = window.innerHeight;
			var ch = this.$refs['main-container'].clientHeight;
			var A =
				-this.$refs['main-container'].getBoundingClientRect().top * this.delta -
				this.desviacion;

			this.yTranslation = A;
		},
		scrollHandler() {
			window.requestAnimationFrame(() => {
				if (this.isInView(this.$refs['image-container'])) {
					this.animateElement();
				}
			});
		},
		isInView(el) {
			let rect = el.getBoundingClientRect();
			return (
				rect.bottom >= 0 &&
				rect.top <= (window.innerHeight || document.documentElement.clientHeight)
			);
		},
		init() {
			const self = this;

			const mainHeight = this.$refs['main-container'].clientHeight;
			const mainWidth = this.$refs['main-container'].clientWidth;

			const imageHeight = this.$refs['image-container'].clientHeight;
			const imageWidth = this.$refs['image-container'].clientWidth;

			const src = this.$el.querySelector('.sc-image-parallax-image img').src;

			var newImg = new Image();
			newImg.onload = function() {
				const vp = window.innerHeight;

				var newH = mainHeight + self.delta * (vp - mainHeight);
				var newW = (newImg.width / newImg.height) * newH;

				if (newW < mainWidth) {
					newW = mainWidth;
					var newH2 = (newImg.height / newImg.width) * newW;
					self.desviacion = (newH2 - newH) / 2;
					newH = newH2;
				}

				self.img.width = newW + 'px';
				self.img.height = newH + 'px';
				self.animateElement();
			};

			newImg.src = src;
		}
	},

	mounted() {
		const self = this;

		if (this.parallax && !this.fixed) {
			window.requestAnimationFrame =
				window.requestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.msRequestAnimationFrame;
		}

		if (typeof ResizeObserver != 'undefined') {
			const resizeObserver = new ResizeObserver(entries => {
				if (
					self.$refs['content-container'].clientHeight + 'px' !=
					self.img.height
				) {
					self.init();
				}
			});

			resizeObserver.observe(this.$refs['content-container']);
		} else {
			this.init();
		}
	},
	created() {
		window.addEventListener('scroll', this.scrollHandler, false);
		window.addEventListener('resize', this.init, false);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollHandler, false);
		window.removeEventListener('resize', this.init, false);
	}
};
</script>
<style scoped lang="scss">
.sc-image-parallax {
	position: relative;
	scroll-behavior: smooth;
	overflow: hidden;
	//z-index: -1;

	.sc-image-parallax-content {
		position: relative;
		z-index: 10;
		&.auto {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			z-index: 10;
		}
	}

	.sc-image-parallax-image {
		width: 100%;
		overflow: hidden;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			max-width: none;
			object-fit: cover;
			object-position: center;
		}

		&.is-parallax {
			left: 0;
			position: absolute;
			will-change: transform;
			right: 0;
			top: 0;
			bottom: 0;
			// -webkit-transition: transform 0.01s;
			// -o-transition: transform 0.01s;
			// transition: transform 0.01s;
			z-index: 0;
		}

		&.is-fixed {
			position: fixed;
			will-change: transform;
			> img {
				height: 100vh;
				max-width: none;
			}
		}
	}

	&.bg::before,
	&.bg-black::before {
		content: '';
		position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0px;
		-webkit-transform: translateZ(0px);
		transform: translateZ(0px);
		height: 100%;
		width: 100%;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.25);
	}
	&.bg-black::before {
		background-color: rgba(0, 0, 0, 0.25);
	}
}
</style>

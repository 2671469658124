(function(){
    window.StyleCre = {};
    var styleCre = window.StyleCre;

    styleCre.vueDataCallBacks = [];

    styleCre.defineVueData = function (data_callback) {
        styleCre.vueDataCallBacks.push(data_callback);
    };

    styleCre.getVueData = function (vm, start_data) {
        return styleCre.vueDataCallBacks.reduce((data, fn) => {
            const fn_data = fn.apply(vm);
            return Object.assign(data, fn_data);
        }, start_data || {})
    };

    styleCre.vueComputedCallBacks = [];

    styleCre.defineVueComputed = function (computed_callback) {
        styleCre.vueComputedCallBacks.push(computed_callback);
    };

    styleCre.getVueComputed = function (vm, start_computed) {
        return styleCre.vueComputedCallBacks.reduce((data, fn) => {
            const fn_data = fn.apply(vm);
            return Object.assign(data, fn_data);
        }, start_computed || {})
    };

    styleCre.vueMethodsCallBacks = [];

    styleCre.defineVueMethods = function (methods_callback) {
        styleCre.vueMethodsCallBacks.push(methods_callback);
    };

    styleCre.getVueMethods = function (vm, start_methods) {
        return styleCre.vueMethodsCallBacks.reduce((data, fn) => {
            const fn_data = fn.apply(vm);
            return Object.assign(data, fn_data);
        }, start_methods || {})
    };

    styleCre.vuePreMountedCallBacks = [];

    styleCre.addPreMountedCallBack = function (callback) {
        styleCre.vuePreMountedCallBacks.push(callback);
    };

    styleCre.executeVuePreMounted = function (vm) {
        return styleCre.vuePreMountedCallBacks.forEach((fn) => {
            fn.apply(vm);
        })
    };

    styleCre.vuePosMountedCallBacks = [];

    styleCre.addPosMountedCallBack = function (callback) {
        styleCre.vuePosMountedCallBacks.push(callback);
    };

    styleCre.executeVuePosMounted = function (vm) {
        return styleCre.vuePosMountedCallBacks.forEach((fn) => {
            fn.apply(vm);
        })
    };
})();
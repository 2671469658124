(function($){
    var defaults = {
        d: 0.1,
    };

    var methods = {
        init : function(options) {
            this.addClass('scparallaxbg')
            this.find('>img,>a>img').hide();
            
            return this.each(function(){
                var self = $(this);
                self.data('settings' ,$.extend(true,{}, defaults, options));
                var settings = self.data('settings');
                self.data('desviacion',0)
                methods.refresh.apply(self);
                $(window).scroll(function(event) {
                    var desviacion =  self.data('desviacion');
                    var img = self.find('>img,>a>img');
                    var offset = self.offset().top;
                    var st = $(window).scrollTop();
                    var vp = $(window).height();
                    var ch = self.height();
                    var x = -(offset-st+ch)/(vp+ch);
                    //var A = Math.round(st-self.offset().top-x*settings.d);
                    x = -x >= 1 ? 1 : x;
                    x = -x <= 0 ? 0 : x;
                    var A = x*settings.d*(vp+ch)-desviacion;
                    //console.log({offset:offset,scrolTop:st,viewPortH:vp,containerHeight:ch,movimiento:x,transalate:A,desviacion:desviacion,seti:settings.d});
                    img.css('transform','translateX(-50%) translateY('+A+'px)');
                });
                $(window).on('resize load',function(event) {
                    methods.refresh.apply(self);
                });
            });
        },
        refresh : function(){
            var self = $(this);
            var settings = self.data('settings');
            var desviacion =  self.data('desviacion');
            var img = self.find('>img,>a>img');
            var vp = $(window).height();
            var ch = self.height();
            var newImg = new Image();
            newImg.onload = function(){
                var newH = self.height()+settings.d*(vp+ch);
                var newW = newImg.width/newImg.height * newH;
                if(newW < self.width()){
                    newW = self.width();
                    var newH2 = newImg.height/newImg.width * newW;
                    desviacion = (newH2-newH)/2;
                    newH = newH2;
                }
                img.width(newW);
                img.height(newH);
                img.show();
                self.data('desviacion',desviacion);

                var offset = self.offset().top;
                var st = $(window).scrollTop();
                var x = -(offset-st+ch)/(vp+ch);
                x = -x >= 1 ? 1 : x;
                x = -x <= 0 ? 0 : x;
                //var A = Math.round(st-self.offset().top-x*settings.d);
                var A = x*settings.d*(vp+ch)-desviacion;
                //console.log({offset:offset,scrolTop:st,viewPortH:vp,containerHeight:ch,movimiento:x,transalate:A,desviacion:desviacion,seti:settings.d});
                img.css('transform','translateX(-50%) translateY('+A+'px)');
            };
            newImg.src = img.prop('src');
        }
        
    };

   $.fn.scparallaxbg = function(methodOrOptions) {
        if ( methods[methodOrOptions] ) {
            return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
            // Default to "init"
            return methods.init.apply(this,arguments);
        } else {
            $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.tooltip' );
        }    
    };
})(jQuery);
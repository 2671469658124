var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-scs-slide",
      class: { active: _vm.active, next: _vm.isNext }
    },
    [
      _c(
        "a",
        {
          staticClass: "v-scs-img",
          attrs: { href: _vm.href, title: _vm.title }
        },
        [
          _vm._t("img", [
            _c("lazy-img", {
              staticClass: "cover h-100 w-100",
              attrs: {
                title: _vm.title,
                alt: _vm.title,
                src: _vm.img,
                root: _vm.root
              }
            })
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm.$slots.default
        ? _c("div", { staticClass: "v-scs-caption-inner" }, [
            _c("div", { staticClass: "v-scs-caption" }, [_vm._t("default")], 2)
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
(function($){
	var defaults = {
		noCover: false,
       	TransitionTime: 200,
		currentItem: null,
		galleryItemSelector: ".scgallery-item",
		beforeOpen: function(Citem,contFunc){
			contFunc();
			return true
		}
    };
    var settings = {}
    var firstTime = true;
    var methods = {
        init : function(options,ele) {
        	var self = this;
    		settings = $.extend({}, defaults, options);
    		self.opening = false;
    		self.closing = false;
    		self.addClass('scgallery');
    		self.find(settings.galleryItemSelector+" a").on("click",function(event) {
    	    	event.preventDefault();
    	    	//$( "#gallery-view #img-masc" ).prop('style','');
    	    	//$( "body" ).css( 'overflow', 'hidden' );
    	    	disableScroll()
    	    	settings.currentItem = $( this ).closest(settings.galleryItemSelector);
    	    	$( "#scgallery-view" ).fadeIn(settings.TransitionTime*3,function(){
    	    		$( "#scgallery-view" ).addClass( 'activeG' );
    	    		methods.showImage.apply(self,[settings.currentItem]);
    	    	});
    	    });
    		if(firstTime){
	    		if($.mobile){
	    			$(".carousel").swiperight(function() {  
	    				self.prevImage.apply(self);
	    			});  
	    			$(".carousel").swipeleft(function() {  
	    				//self.nextImage.apply(self);
	    			}); 
	    		}
	    		if($("#scgallery-view").length==0){
	    	        var galleryView = '\
	    	        	<div id="scgallery-view" tabindex="-1">\
	    					<button type="button" id="gv-next" aria-label="Next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>\
	    					<button type="button" id="gv-prev" aria-label="Prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>\
	    					<button type="button" id="gv-close" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>\
	    					<div id="img-masc"><div id="img" /></div>\
	    				</div>\
	    	        ';
	    	        $(galleryView).prependTo('body').hide();

		    	    $( "#scgallery-view #img-masc" ).hide();
	    	    }
	    	    $("#scgallery-view").on('click', function(event) {
	    	    	event.preventDefault();
	    	    	if($(event.target).is("#scgallery-view"))
	    	    		methods.closeImage.apply(self,[settings.currentItem, function() {
	    	    			return true;
	    	    		}]);
	    	    });
		        $( "#scgallery-view #gv-close" ).on("click", function( event ) {
		    		methods.closeImage.apply(self,[settings.currentItem, function() {
		    			return true;
		    		}]);
		    	} );

		    	$( "#scgallery-view #gv-next" ).on("click", function( event ) {
		    		methods.nextImage.apply(self);
		    	} );

		    	$( "#scgallery-view #gv-prev" ).on("click",function( event ) {
		    		methods.prevImage.apply(self);
		    	});
		    	$(document).on('keyup',function(e) {
    	    	    switch(e.keyCode){ 
    	    	    	case 27: // escape key
    	    	    		e.preventDefault();
	    	    	     	methods.closeImage.apply(self,[settings.currentItem, function() {
	    	    	     		return true;
	    	    	     	}]);
	    	    	     	break;
	    	    	    case 37:
	    	    	    case 38:
	    	    	   		e.preventDefault();
	    	    	    	methods.prevImage.apply(self);
	    	    	    	break;
	    	    	    case 39:
	    	    	    case 40:
	    	    	    	e.preventDefault();
	    	    	    	methods.nextImage.apply(self);
	    	    	    	break;
	    	    	    default:
	    	    	    	break;
    	    	    }
    	    	});
    	    	$(window).resize(function(event) {
    	    		if($("#scgallery-view").is(":visible")){
    	    			//alert("fsj")
	    	    		let newW = $("#scgallery-view #img-masc").data('oWidth');
	    	    		let newH = $("#scgallery-view #img-masc").data('oHeight');
	    	    		if(newW > $("#scgallery-view").width()){
	    					newH = newH * $("#scgallery-view").width()/newW
	    					newW = $("#scgallery-view").width();
	    				}
	    				if(newH > $("#scgallery-view").height()){
	    					newW = newW * $("#scgallery-view").height()/newH;
	    					newH = $("#scgallery-view").height();
	    				}
						$( "#scgallery-view #img-masc" ).css({
							height: newH,
							width: newW,
							top: "50%",
							left: "50%",
						});
					}
    	    	});
    	    	if(typeof $.fn.swiperight !== 'undefined'){
    	    		$("#scgallery-view").swiperight( function() {
						methods.prevImage.apply(self);
					} );
	    		}
	    		if(typeof $.fn.swipeleft !== 'undefined'){
    	    		$("#scgallery-view").swipeleft( function() {
						methods.nextImage.apply(self);
					} );
	    		}
	    	    firstTime = false;
	    	}
        },
        showImage : function(element){
        	var self = this;
	    	if ( !self.closing && !self.opening ) {
	    		self.opening = true;

	    		var loaded = false;

	    		settings.beforeOpen.apply(self,[
	    			settings.currentItem,
	    			function(){
			    		var thumbsrc, src;

			    		if(element.find('img').data('original') != undefined){
				    		src = element.find('img').data('original');
							thumbsrc = element.find('img').prop('src');
				    	} else thumbsrc = src = element.find('img').prop('src');

						console.log('d',thumbsrc,src);

			    		$("#scgallery-view #img-masc>img").hide();
			    		$("#scgallery-view #img-masc").css('background-image', 'url('+thumbsrc+')');

			    		var startW = element.width();
			    		var startH = element.height();

			    		$("#scgallery-view #img-masc").css('transition-duration', '0s');
			    		$("#scgallery-view #img-masc").width(startW);
			    		$("#scgallery-view #img-masc").height(startH);

			    		var top = element.children().offset().top - $(window).scrollTop()+startH/2;
			    		var left = element.children().offset().left+startW/2;
			    		$( "#scgallery-view #img-masc" ).css({
			    			height: startH,
			    			width: startW,
			    			top: top,
			    			left: left,
			    		});
			    		
			    		
			    		$( "#scgallery-view #img-masc" ).fadeIn(settings.TransitionTime,function(){
			    			var imgEl =  document.createElement('img');
			    			imgEl.src = src;
			    			console.log("img open - " + src);
							
			    			var poll = setInterval(function () {
							    if (imgEl.naturalWidth) {
							        clearInterval(poll);
							        console.log(imgEl.naturalWidth, imgEl.naturalHeight);

							        if(!loaded)
			    						$("#scgallery-view #img-masc").addClass('loading');

		    	    				var newW = imgEl.naturalWidth;
		    	    				var newH = imgEl.naturalHeight;
		    	    				$("#scgallery-view #img-masc").data('oWidth', newW);
		    	    				$("#scgallery-view #img-masc").data('oHeight', newH);

		    	    				if(newW > $("#scgallery-view").width()){
		    	    					newH = newH * $("#scgallery-view").width()/newW
		    	    					newW = $("#scgallery-view").width();
		    	    				}
		    	    				if(newH > $("#scgallery-view").height()){
		    	    					newW = newW * $("#scgallery-view").height()/newH;
		    	    					newH = $("#scgallery-view").height();
		    	    				}
		        					element.addClass('activeG');
		        					$("#scgallery-view #img-masc").css('transition-duration', (settings.TransitionTime/1000)+'s');
		        					$( "#scgallery-view #img-masc" ).css({
		        						height: newH,
		        						width: newW,
		        						top: "50%",
		        						left: "50%",
		        					});
		        					$( "#scgallery-view #img-masc" ).one( 'transitionend',function(){
			    						self.opening = false;
			    						//$("#scgallery-view #img-masc>img").prop("src",src).show();
			    						$("#scgallery-view #img").css('background-image', 'url('+src+')').show();	
			    						self.trigger("OnOpened.gallery");
			    					});
							    }
							}, 10);

							$("#scgallery-view #img-masc>#img").data('imageOBJ',new Image());
			    			var newImg = $("#scgallery-view #img-masc>#img").data('imageOBJ')

			    			newImg.onload = function(){
			    				loaded = true;
			    				console.log("img loaded - " + newImg.src);

		    					$("#scgallery-view #img-masc").removeClass('loading');
			    			}
			    			newImg.src = src;
			    		});
			    	}
			    ]);
	    	}
	    },// IS
        closeImage : function(element,callback) {
        	var self = this;
	    	callback = callback || function() {return true;};
			if ( !self.closing && !self.opening ) {
				self.closing = true;

				var newImg = $("#scgallery-view #img-masc>#img").data('imageOBJ');
				newImg.onload = null;
				//newImg.src = '';

				var startW = element.width();
				var startH = element.height();
				
				var top = element.children().offset().top - $(window).scrollTop()+startH/2;
				var left = element.children().offset().left+startW/2;

				$("#scgallery-view #img-masc>#img").prop("style",'').hide();

				$( "#scgallery-view #img-masc" ).css({
					height: startH,
					width: startW,
					top: top,
					left: left,
				});
				$( "#scgallery-view #img-masc" ).one( 'transitionend',function(){
					element.removeClass( 'activeG' );
					$( "#scgallery-view #img-masc").css('transition-duration', '0s');
					$( "#scgallery-view #img-masc").fadeOut(settings.TransitionTime, function() {
						self.closing = false;
						self.trigger("OnClosed.gallery");
						if(callback.apply(self,[element])){
							$( "#scgallery-view" ).removeClass( 'activeG' );
							$( "#scgallery-view").fadeOut( settings.TransitionTime*3, function(){
								enableScroll()
								//$( "body" ).css( 'overflow', 'auto' );

							});
						}
					});						
				});

			}
		},// GOOD
        nextImage : function(){
        	var self = this;
			if ( !self.closing && !self.opening ){
				methods.closeImage.apply(self,[settings.currentItem, function() {
						return false
				}]);
				var galleryItems = settings.currentItem.closest('.scgallery').find(settings.galleryItemSelector);
				var currentIndex = galleryItems.index(settings.currentItem);
				var nextIndex = currentIndex == galleryItems.length-1 ?  0 : currentIndex+1;
				var nextItem = galleryItems.eq(nextIndex);
				settings.currentItem = nextItem;
				self.one("OnClosed.gallery",function(){
					methods.showImage.apply( self,[settings.currentItem] );
				});
			}
		},
		prevImage: function(){
			var self = this;
			if ( !self.closing && !self.opening ) {
				methods.closeImage.apply( self,[settings.currentItem, function(){
					return false;
				}]);
				var galleryItems = settings.currentItem.closest('.scgallery').find(settings.galleryItemSelector);
				var currentIndex = galleryItems.index(settings.currentItem);
				var nextIndex = currentIndex == 0 ? galleryItems.length-1 : currentIndex-1;
				var prevItem = galleryItems.eq(nextIndex);
				settings.currentItem = prevItem;
				self.one("OnClosed.gallery",function(){
					methods.showImage.apply( self,[prevItem ]);
				});
			}
		}
    };

    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

	function preventDefault(e) {
	  e = e || window.event;
	  if (e.preventDefault)
	      e.preventDefault();
	  e.returnValue = false;  
	}

	function preventDefaultForScrollKeys(e) {
	    if (keys[e.keyCode]) {
	        preventDefault(e);
	        return false;
	    }
	}

	function disableScroll() {
	  if (window.addEventListener) // older FF
	      window.addEventListener('DOMMouseScroll', preventDefault, false);
	  window.onwheel = preventDefault; // modern standard
	  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
	  window.ontouchmove  = preventDefault; // mobile
	  document.onkeydown  = preventDefaultForScrollKeys;
	}

	function enableScroll() {
	    if (window.removeEventListener)
	        window.removeEventListener('DOMMouseScroll', preventDefault, false);
	    window.onmousewheel = document.onmousewheel = null; 
	    window.onwheel = null; 
	    window.ontouchmove = null;  
	    document.onkeydown = null;  
	}

   $.fn.scgallery = function(methodOrOptions) {
   		self = this;
        if ( methods[methodOrOptions] ) {
            return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
            // Default to "init"
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.tooltip' );
        }    
    };
})(jQuery);
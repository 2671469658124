<template>
    <div :id="name" class="v-scslider" data-ride="carousel" v-touch:swipe="swipeHandler">
        <ol class="v-scs-indicators" >
            <li v-for="index in slides.length" :class="{active: index - 1 == currentIndex}">
                <span class="before" :style="{width: (index - 1 == currentIndex) ? timerPRC+'%' : 0}"></span>
            </li>
        </ol>
        <div class="v-scs-inner" role="listbox" :class="{sliding: sliding, right: currentDirection == 'right', left: currentDirection == 'left'}">
            <slot ref="slides"></slot>
        </div>
        <a :href="'#'+name" @click.prevent="prevSlide" class="left v-scs-control" role="button" data-slide="prev">
            <slot name="prev-control">
                <i class="far fa-chevron-left" aria-hidden="true"></i>
                <span class="sr-only">{{ prevLabel }}</span>
            </slot>
        </a>
        <a :href="'#'+name" @click.prevent="nextSlide" class="right v-scs-control" role="button" data-slide="next">
            <slot name="next-control">
                <i class="far fa-chevron-right" aria-hidden="true"></i>
                <span class="sr-only">{{ nextLabel }}</span>
            </slot>
        </a>
    </div>
</template>

<script>
    import Vue2TouchEvents from 'vue2-touch-events'

    Vue.use(Vue2TouchEvents);

    function Timer(callback, interval) {
        var timerId, startTime, remaining = 0;
        var state = 0; //  0 = idle, 1 = running, 2 = paused, 3= resumed 4 = stoped

        this.pause = function () {
            if (state != 1 && state != 3) return this;

            if(state == 1)
                remaining = interval - (new Date() - startTime);
            else
                remaining = remaining - (new Date() - startTime);

            window.clearInterval(timerId);
            state = 2;

            return this;
        };

        this.resume = function () {
            if (state != 2 && state != 4) return this;
            if(state == 4){
                this.start();
                return this
            }

            state = 3;
            startTime = new Date();
            window.setTimeout(this.timeoutCallback, remaining);

            return this;
        };

        this.stop = function() {
            if (state == 1 && state == 3) return this;

            state = 4;
            window.clearInterval(timerId);

            return this;
        };

        this.start = function() {
            if (state != 4 && state != 0) return this;

            startTime = new Date();
            timerId = window.setInterval(callback, interval);
            state = 1;

            return this;
        };

        this.timeoutCallback = function () {
            if (state != 3) return this;

            callback();

            startTime = new Date();
            timerId = window.setInterval(callback, interval);
            state = 1;

            return this
        };

        this.getRemaining = function(){
            switch (state) {
                case 1:
                    return interval - (new Date() - startTime);
                case 3:
                    return remaining - (new Date() - startTime);
                case 2:
                    return remaining;
                case 4:
                    return interval;
                default:
                    return 0;
            }
        };

        this.start();
    }

    export default {
        name: "SCSlider",
        props: {
            name: {
                require: true,
                type: String
            },
            slideTime: {
                default: 5000,
                type: Number
            },
            autoSlide: {
                default: true,
                type: Boolean
            },
            autoSlideDirection: {
                default: 'right',
                type: String
            }
        },
        computed: {
            slides(){
                return this.$slots.default.filter((elem)=> elem.tag == 'vue-component-2-SCSlide');
            },
            timerPRC(){
                if(!this.timer) return 0;

                if(!this.now) this.now = new Date();

                const consumed = this.slideTime - this.timer.getRemaining();

                return consumed*100/this.slideTime;
            }
        },
        data(){
            return {
                now: null,
                timer: null,
                currentIndex: 0,
                currentSlide: null,
                currentDirection: null,
                noTrasition: false,
                sliding: false
            }
        },
        watch: {
            autoSlide() {
                if(!this.autoSlide) this.timer.pause();
                else this.timer.resume();
            }
        },
        methods: {
            swipeHandler(dir,event){
                switch (dir) {
                    case 'left':
                    case 'down':
                        this.nextSlide();
                        break;
                    case 'right':
                    case 'up':
                        this.prevSlide();
                        break;

                }
            },
            nextSlide(){
                var self = this;

                if(this.sliding) return;

                this.timer.stop();

                const nextIndex = (this.currentIndex >= this.slides.length - 1) ? 0 : this.currentIndex + 1;
                const nextSlide = this.slides[nextIndex];
                self.currentDirection = 'right';
                nextSlide.componentInstance.$props.isNext = true;

                var reset = function () {
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'.split(' ').forEach(event=> {
                        self.currentSlide.elm.removeEventListener(event, reset, false);
                    });

                    nextSlide.componentInstance.$props.isNext = false;
                    nextSlide.componentInstance.$props.active = true;
                    self.currentSlide.componentInstance.$props.active = false;
                    self.currentIndex = nextIndex;
                    self.currentSlide = nextSlide;
                    self.currentDirection = null;
                    self.sliding = false;

                    if(self.autoSlide) self.timer.start();
                };

                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'.split(' ').forEach(event=>{
                    self.currentSlide.elm.addEventListener(event, reset, false);
                });

                this.$nextTick(()=>{
                    void self.$el.offsetWidth;
                    void nextSlide.componentInstance.$el.offsetWidth;
                    void self.currentSlide.componentInstance.$el.offsetWidth;
                    self.sliding = true;
                });
            },
            prevSlide(){
                var self = this;

                if(this.sliding) return;

                this.timer.stop();

                const nextIndex = (this.currentIndex == 0) ? this.slides.length - 1 : this.currentIndex - 1;
                const nextSlide = this.slides[nextIndex];
                self.currentDirection = 'left';
                nextSlide.componentInstance.$props.isNext = true;

                var reset = function () {
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'.split(' ').forEach(event=> {
                        self.currentSlide.elm.removeEventListener(event, reset, false);
                    });

                    nextSlide.componentInstance.$props.isNext = false;
                    nextSlide.componentInstance.$props.active = true;
                    self.currentSlide.componentInstance.$props.active = false;
                    self.currentIndex = nextIndex;
                    self.currentSlide = nextSlide;
                    self.currentDirection = null;
                    self.sliding = false;

                    if(self.autoSlide) self.timer.start();
                };

                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'.split(' ').forEach(event=>{
                    self.currentSlide.elm.addEventListener(event, reset, false);
                });

                this.$nextTick(()=>{
                    void self.$el.offsetWidth;
                    void nextSlide.componentInstance.$el.offsetWidth;
                    void self.currentSlide.componentInstance.$el.offsetWidth;
                    self.sliding = true;
                });
            }
        },
        mounted(){
            var self = this;

            this.currentSlide = this.slides[0];
            this.currentSlide.componentInstance.$props.active = true;

            this.timer = new Timer(function () {
                if (self.autoSlideDirection == 'right')
                    self.nextSlide();
                else
                    self.prevSlide();
            }, self.slideTime);

            setInterval(function () {
                self.now = Date.now()
            }, 500)

            //if(!this.autoSlide) this.timer.pause();
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
.v-scslider {
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;

    .v-scs-inner {
        position: relative;
        width: 100%;
        height: 100%;
        left: 0px;
        overflow: hidden;
        -webkit-transition: 1s linear left;
        -o-transition: 1s linear left;
        transition: 1s linear left;
        white-space: nowrap;

        &.right {
            .v-scs-slide.next {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(100%);
            }

            &.sliding .v-scs-slide.active {
                transform: translateX(-100%);
            }

            &.sliding .v-scs-slide.next {
                transform: translateX(0%);
            }
        }

        &.left {
            .v-scs-slide.next {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(-100%);
            }

            &.sliding .v-scs-slide.active {
                transform: translateX(100%);
            }

            &.sliding .v-scs-slide.next {
                transform: translateX(0%);
            }
        }
    }

    /*.scs-inner.sliding > .slide{
        transform: scale(.8);
    }*/
    .v-scs-control {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 50px;
        width: 50px;
        opacity: 0.5;
        filter: alpha(opacity=50);
        font-size: 30px;
        color: #ffffff;
        margin: auto;
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.8);
        line-height: 50px;

        i {
            line-height: 50px;
        }

        &.left {
            background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
            background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
        }

        &.right {
            left: auto;
            right: 0;
            background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
            background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
        }

        &:hover,
        &:focus {
            outline: 0;
            color: #ffffff;
            text-decoration: none;
            opacity: 0.9;
            filter: alpha(opacity=90);
        }

        .icon-prev,
        .icon-next,
        .glyphicon-chevron-left,
        .glyphicon-chevron-right {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            z-index: 5;
            display: inline-block;
        }

        .icon-prev,
        .glyphicon-chevron-left {
            left: 50%;
            margin-left: -10px;
        }

        .icon-next,
        .glyphicon-chevron-right {
            right: 50%;
            margin-right: -10px;
        }

        .icon-prev,
        .icon-next {
            width: 20px;
            height: 20px;
            line-height: 1;
            font-family: serif;
        }

        .icon-prev:before {
            content: '\2039';
        }

        .icon-next:before {
            content: '\203a';
        }

        & > .fa {
            line-height: 50px;
        }
    }

    .v-scs-indicators {
        position: absolute;
        bottom: 0px;
        left: 50%;
        z-index: 15;
        width: 60%;
        margin-left: 0px;
        padding-left: 0;
        list-style: none;
        text-align: center;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        li {
            display: inline-block;
            width: 50px;
            margin: 1px;
            height: 4px;
            text-indent: -999px;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.5);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            overflow: hidden;
            border-radius: 1px;

            & .before {
                background-color: #000;
                display: block;
                width: 0%;
                height: 100%;
                -webkit-transition-property: none;
                -o-transition-property: none;
                transition-property: none;
            }

            &.active .before {
                -webkit-transition: width linear;
                -o-transition: width linear;
                transition: width linear;
                -webkit-transition-duration: 0.5s;
                -o-transition-duration: 0.5s;
                transition-duration: 0.5s;
            }
        }
    }

    .notransition {
        -webkit-transition: none 0s !important;
        -o-transition: none 0s !important;
        transition: none 0s !important;
    }

    .v-scs-caption-inner {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: auto;
    }

    .v-scs-caption {
        display: inline-block;
        position: relative;
        margin: auto;
        background: rgba(51, 51, 51, 0.85);
        padding: 25px;
        color: white;
        outline: 5px #333 solid;
        outline-offset: 3px;
        max-width: 80%;
        white-space: normal;
        text-align: center;

        &.animate:not(.animated) {
            visibility: hidden;
        }

        h1 {
            font-size: 42px;
            color: #f6f6f6;
            margin: auto;
        }

        h1,
        h2,
        h3 {
            text-transform: uppercase;
        }
    }

    @media (max-width: 767px) {
        .v-scs-control {
            display: none;
        }
    }
    @media (min-width: 768px) {
        .v-scs-caption {
            max-width: 70%;

            h1 {
                font-size: 48px;
            }
        }
    }
    @media (min-width: 992px) {
        v-scs-caption h1 {
            font-size: 64px;
        }
    }
    @media (min-width: 1200px) {
        .v-scs-caption h1 {
            font-size: 72px;
        }
    }
    /*.scs-caption:not(.animated){
        visibility: hidden;
    }*/
}
</style>
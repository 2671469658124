let hashses = {};
module.exports = class Unserializer{


	static getAllHashses(object){
		const self = this;


		if(Array.isArray(object)){
			object.forEach(subObj => self.getAllHashses(subObj))
		}else if(typeof object == 'object' && object){
			hashses[object.object_hash] = object;
			//delete object.object_hash;
			for(const prop in object){
				if(typeof object[prop] == 'object')
					self.getAllHashses(object[prop]);
			}
		}
	}

	static unserializeObject(object){
		const self = this;

		if(Array.isArray(object)){
			object.forEach(subObj => self.unserializeObject(subObj))
		}else if(typeof object == 'object'){
			for(const prop in object){
				var hash = null;
				if(typeof object[prop] == 'string' && (hash = /^\*RECURSION\*\s#([a-zA-Z0-9]*)/gi.exec(object[prop]))){
					object[prop] = hashses[hash[1]];
				}else if(typeof object[prop] == 'object'){
					self.unserializeObject(object[prop]);
				}
			}
		}
	}

	static unserialize(object){
		hashses = {};
		this.getAllHashses(object);
		//console.log(hashses);
		const a = this.unserializeObject(object);
		hashses = {};

		return object;
	}
};
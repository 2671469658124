import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import moment from 'moment';
import 'moment/locale/es';
import numeral from 'numeral';
import 'numeral/locales/es-es';
import './numeral.en-ES';
import numFormat from 'vue-filter-number-format';
import vClickOutside from 'v-click-outside';
import VClamp from 'vue-clamp';
import ImageParallax from '../vue/ImageParallax';
import LazyImg from '../vue/LazyImg'
import SCSlide from '../vue/SCSlider/SCSlide'
import SCSlider from '../vue/SCSlider/SCSlider'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Vue2TouchEvents from 'vue2-touch-events'
import qs from "query-string";
import VueContentLoading from "vue-content-loading";

window.Vue = Vue;
window._ = _;

moment.locale(LANG_CODE);
Vue.use(VueMoment, {moment});
Vue.component('sc-slide', SCSlide);
Vue.component('sc-slider',SCSlider);
Vue.component('vue-slick-carousel',VueSlickCarousel);

Vue.mixin({
    data: function() {
        return {
            ROOT: ROOT,
            ROOTAPI: ROOTAPI,
            URL: URL,
            tokenPromise: null,
            authorized: false,
            access_token: null
        }
    },
    methods: {
        imgUrlAlt(event) {
            event.target.src = ROOT+"assets/img/placeholder-img.png"
        },
        getAccessToken() {
            return this.$store.dispatch('getAccessToken');
        },
        getAccessTokenO() {
            const self = this;
            if (!this.tokenPromise) {
                console.log('ako',!this.authorized,this.access_token,self.$moment());
                this.tokenPromise =
                    !this.authorized ||
                    this.access_token.expires < self.$moment() ?
                        axios
                            .post(
                                ROOTAPI + 'oauth/access_token/',
                                qs.stringify({
                                    grant_type: 'client_credentials',
                                    client_id: 'FLUNA_WEB',
                                    client_secret: 'abc123',
                                    scope: 'reviews'
                                }),
                                {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                                }
                            )
                            .then(res => {
                                self.authorized = true;

                                const access_token = {
                                    type: res.data.token_type,
                                    token: res.data.access_token,
                                    expires: self.$moment().add(res.data.expires_in, 's')
                                };
                                self.access_token = access_token;
                                self.tokenPromise = null;
                                return access_token;
                            })
                            .catch(error => error)
                        :
                            Promise.resolve(this.access_token);
            }

            return this.tokenPromise;
        }
    }
});

numeral.locale(COUNTRY_CODE.toLowerCase().replace('_','-'));
Vue.filter('numFormat', numFormat(numeral));
Vue.use(vClickOutside);
Vue.use(VueAxios, axios);
Vue.use(Vue2TouchEvents);

Vue.component('vue-content-loading', VueContentLoading);
Vue.component('v-clamp',VClamp);
Vue.component('image-parallax', ImageParallax);
Vue.component('lazy-img',LazyImg);

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';

/*Vue.filter('number_format', function(value,decimales){
    if(typeof decimales == 'undefined')
        decimales = 2;
    return value.toLocaleString(LANG_CODE,{maximumFractionDigits: decimales,minimumFractionDigits: decimales})
});*/

/*Vue.filter('numFormat', (value,format) => {
    if(!format) format = '0,0.00';
    return numeral(value).format(format)
});*/


Vue.filter('replace', function(value,needle,replace){
    return value.replace(needle,replace);
});

Vue.filter('tel_format', function(value,countryCode){
    if(typeof countryCode == 'undefined')
        countryCode = true;

    var matches;

    if(matches = value.match(/^(\(?\s*(?:00|\+)?34\s*\)?[\s|\-|\.]?)?([6789][\s|\-|\.]?(?:[0-9][\s|\-|\.]?){8})$/)){
        value = matches[2].replace(/[\s|\-|\.]+/g,'');

        var chunks = value.match(/^(.{3})(.{2})(.{2})(.{2})$/)
        chunks.shift();

        value = (countryCode ? '+(34) ' : '')+(chunks.join(' '));
    }

    return value;
});